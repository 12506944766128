<div class="container-xxl mx-auto px-0 my-2 mt-3">
  <div class="container-title">
    <div class="title-widget">Our Providers</div>
    <a [routerLink]="['/providers/']">
      <fa-icon class="icon" [icon]="faArrowRight" [size]="'1x'"></fa-icon>
    </a>
  </div>
  <swiper-container init="false" #swiperProviders class="swiper-container">
    @for (item of providerList; track item._id;) {
      <swiper-slide class="swiper-item">
        <a [routerLink]="['/provider/'+(item['name'] | slugName)]">
          <img
            ngSrc="../../../template/green/assets/images/providers/box/{{websiteJSONData.misc.provider_image+'/'+(item.name | slugName)}}.webp"
            [title]="item.name" [alt]="item.name" width="500" height="500" loading="eager"/>
        </a>
      </swiper-slide>
    }
  </swiper-container>
</div>