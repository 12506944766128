import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Inject,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Router, RouterModule } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faEyeSlash,
  faEye,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { ToastComponent } from '@componentpath/parts/toast/toast.component';
import { ToastService } from '@componentpath/services/toast.service';
import { register } from 'swiper/element/bundle';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination } from 'swiper/modules';
import { DataService } from '@componentpath/services/data.service';

@Component({
  selector: 'app-login-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './login-home.component.html',
  styleUrl: './login-home.component.scss',
  imports: [CommonModule, FontAwesomeModule, FormsModule, ToastComponent, RouterModule, NgOptimizedImage],
})
export class LoginHomeComponent {
  faUser: IconProp = faUser;
  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  faLock: IconProp = faLock;
  subscription: Subscription = new Subscription();

  username = '';
  password = '';
  isPasswordVisible = false;
  isLoggedin: boolean = false;
  providerLists: Array<any> = [];
  selectedProvider: Array<any> = [];
  websiteJSONData: any = this.dataService.websiteJSONData;

  @ViewChild('errorToastTpl') errorToastTpl!: TemplateRef<any>;
  @ViewChild('defaultTemplate') defaultTemplate!: TemplateRef<any>;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private auth: AuthService,
    private request: RequestService,
    private router: Router,
    private toastService: ToastService,
    private dataService: DataService
  ) {}

  // public config: SwiperOptions = {
  //   modules: [Navigation, Pagination, A11y, Mousewheel, Grid],
  //   slidesPerView: 3,
  //   loop: true,
  //   centeredSlides: true,
  //   grid: {
  //     fill: 'row',
  //   },
  //   autoplay: {
  //     delay: 500,
  //     disableOnInteraction: true,
  //   },
  // };

  public config: SwiperOptions = {
    init: false,
    modules: [Navigation, Pagination, A11y, Mousewheel, Grid],
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: true,
  };

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public login(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.username || !this.password) {
        this.toastService.show({
          template: this.errorToastTpl,
          classname: 'bg-danger text-light',
          delay: 8000,
          context: { message: 'Please complete all required fields.' },
        });
        return;
      }
      this.request.login(this.username, this.password).subscribe({
        next: (response) => {
          if (response.code === 200) {
            this.auth.setLogin(response.data, {});
            this.router.navigate(['/pin']);
          } else {
            console.error('Login failed:', response.message);
            const message = response.message || 'Login failed';
            this.toastService.show({
              template: this.errorToastTpl,
              classname: 'bg-danger text-light',
              context: { message },
              delay: 8000,
            });
          }
        },
        error: (error) => {
          const errorMessage =
            error.error.messages[0].message || 'Login failed';
          this.toastService.show({
            template: this.errorToastTpl,
            classname: 'bg-danger text-light',
            context: { message: errorMessage },
            delay: 8000,
          });
        },
        complete: () => {
          // console.log(this.auth.sessionValue);
        },
      });
    }
  }

  toUppercase(event: string): void {
    this.username = event.toUpperCase();
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
        },
      })
    );
  }

  groupingArray(arr: any, data: number) {
    const group = [];
    for (let i = 0; i < arr.length; i += data) {
      group.push(arr.slice(i, i + data));
    }
    return group;
  }

  setRowProviderList(){
    this.selectedProvider = this.groupingArray(this.providerLists, 12);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscriptionData();
      this.auth.getUserData();
      this.providerLists = this.dataService.providerJSONData['records'];
      this.setRowProviderList();
      register();
    }
    // this.promo();
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
